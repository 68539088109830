export function headerFunc() {
  // ハンバーガーメニュー実装
  $('.navToggle').on('click', function() {
    $('.navToggle').toggleClass('closeNav');
    $('.headerNav').toggleClass('openNav');
    $('.navToggle').toggleClass('closeNav');

    if($('.navToggle').text() == 'Menu') {
      $('.navToggle').text('Close');
    } else {
      $('.navToggle').text('Menu');
    }
  });

  $('.headerNavList__link').on('click', function() {
    $('.navToggle').removeClass('closeNav');
    $('.navToggle__text').text('Menu');
    $('.headerNav').removeClass('openNav');
  });

  $('.headerNavList a').on('click', function() {
    $('.headerNav').removeClass('openNav');
    $('.navToggle').removeClass('closeNav');
    $('body').removeClass('menuOpen');
  });
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}